//const version = "1.1.2"
//const version = "1.2.0"
//const version = "1.3.1";
//const version = "1.4.0";
//const version = "1.5.0";
//const version = "1.6.0";
//const version = "1.7.0";
//const version = "1.8.0";
//const version = "1.9.0";
//const version = "1.12.1";
//const version = "1.13.0"; //practice promotions sprint
//const version = "1.13.1"; // reactive modal hotfix
//const version = "1.13.2" //mobile buttons hotfix
// const version = "1.14.0";
// const version = "1.15.0"; //ES Sprint
// const version = "1.15.1"; //view patient fix
// const version = "1.15.4"; // fix view clinic exercises
// const version = "1.16.0"; //filters
// const version = "1.16.2"; //image fix
// const version = "1.17.0"; //1.17.0 release
// const version = "1.17.1"; //image fix
// const version = "1.17.2"; //image fix
// const version = "1.17.3"; //added inspectlet
//const version = "1.17.4"; //added kioko.css
// const version = "1.18.0"//mobile chat
// const version = "1.18.1"//fix rotate on keyboard bug
// const version = "1.19.0"//release
// const version = "1.19.1"//Update user email release
// const version = "1.19.2"
//const version = "1.20.0"
// const version = "1.22.0"; //token update release
//const version = "1.22.1"; //super admin fix
// const version = "1.23.0"; //super admin fix
// const version = "1.23.1"; //video fix
// const version = "1.23.2"; //video update
// const version = "1.23.3"; //adherence log fix
// const version = "1.23.4"; //logo fix
// const version = "1.23.5"; //Misc. issues fix
// const version = "1.24.0"; //Help center link
// const version = "1.24.1"; //reposition Help center link
// const version = "1.25.0"; //favorites added
// const version = "1.25.1"; //favorites bug fixes
//const version = "1.25.2"; //favorites bug fixes
// const version = "1.25.3"; //favorites fix filters
// const version = "1.25.4"; //fix includes bug favorites
// const version = "1.25.5"; //added favorites to view exercises
// const version = "1.25.6"; //fixed styling and added help center link to welcome section
// const version = "1.26.0"; //preliminary update to prescription creation
// const version = "1.26.1" //update to prescription creation fixing templates
// const version = "1.26.2" //bug fix to prescription completion
// const version = "1.26.3" //fix perdiem dropdown
// const version = "1.26.4" //fix prescription text areas
// const version = "1.26.5" //remove network error
// const version = "1.26.6" //fix perdiem
// const version = "1.26.7" //view exercises and prescription instruction
//const version = "1.27.0" //User Form Update
//const version = "1.27.1" //User Form Update
// const version = "1.27.2" //Minor capitalization bug fixes
//const version = "1.28.0" //Template / prescription parameter fix
// const version = "1.29.1" //Integration / disable messaging temporarily
// const version = "1.29.2" //Fix The templates
// const version = '1.29.3'; // Sync App customization forms to backend
// const version = '1.29.4'; // Added form validations and notification after form submission
// const version = '1.29.5'; // Frequency toggle and fields
// const version = '1.29.6'; // Added insertion of url to customized buttons
// const version = '1.29.7'; // Added announcement to administrator
// const version = '1.29.8'; // Populate app customization data to announcement and app customization pages
// const version = '1.29.9'; // Added color field on rewards form
// const version = '1.30.0'; // Updated contents and added tooltips in app customization
// const version = '1.30.1'; // Set push notification announcement form
// const version = '1.30.2'; // Finishing push notification announcement form and displaying data to push notification table
// const version = '1.30.3'; // Field validation and success and error alerts on push notification announcement form
// const version = '1.30.4'; // Handle saving of exercise level frequency to prescriptions and templates form
// const version = '1.30.5'; // Display frequency level details in patients
// const version = '1.30.6'; // Handling display of prescription level frequency in provider screen
// const version = '1.30.7'; // Fix timeToSend calculation and added sending push notifications after submitting push notifications form
// const version = '1.30.8'; // Change Date and Time to send format to 12 hour clock time
// const version = '1.30.9'; // Remove Date and Priority field and set default values for TTL and Priority on submit
// const version = '1.40.0'; // Fix admin dashboard ui, fix navigation and remove beta badges on video call, added notification for upload profile photo
// const version = '1.40.1'; // Fix upload photo on edit therapist
// const version = '1.40.2'; // Messaging screen enhancement, set per location phone number on print prescription PDF, number range on sets/reps on template and prescription
// const version = '1.40.3'; // Misc. Fixes/Enhancements
// const version = '1.40.4'; // fix redirects
// const version = '1.41.0'; // Change assigned therapist and other fixes
// const version = '1.43.0'; // UI updates and messaging fixes
// const version = '1.43.1'; //infinite loading fix
// const version = '1.43.2'; //update messaging timestamps
// const version = '1.43.3'; //fix update messaging timestamps
// const version = '1.43.4'; //fix update messaging timestamps
// const version = '1.43.5'; //fix upload exercise image on add/edit exercise form
// const version = '1.44.0'; //added logo and nav dropdown, fix every page's spacing
//const version = '1.44.1'; //fixed de-select check issue
// const version = '2.0.0'; //UI Rework
//const version = '2.0.2';// Mobile patient hotfix
//const version = '2.1.0';
// const version = '2.2.1';//fix search issues
// const version = '2.2.2';//RTM
// const version = '2.2.3';//Hotfix patient location
// const version = '2.2.4';//Hotfix patient location fixes
// const version = '2.2.5';//Hotfix patient location fixes
// const version = '3.2.0'; //RTM timer issue fixes, beta tag, empty text for billing logs
// const version = '3.2.1'; // fix updating of clinical logs after saving manually
// const version = '3.2.2'; //RTM timer fixes
// const version = '3.2.3'; //RTM timer fixes when accessing non-patients page, fix beta tag placement on RTM dashboard, edit patient fixes
// const version = '3.2.4'; //added RTM timer fixes
// const version = '3.2.5'; //added RTM timer fixes
// const version = '3.2.6'; //added RTM timer fixes
// const version = '3.2.7'; //Discharge fixes, Forbidden page for accounts with groups deactivated
// const version = '3.3.2'; //Patient profile button fixes
// const version = '3.3.3'; //Hide PT University and Care Plan from patient profile
// const version = '3.3.4'; //Prod fixes
// const version = '3.3.5'; //Prod fixes
// const version = '3.3.6'; //Prod fixes
// const version = '3.3.7'; //Prod fixes
// const version = '3.3.8'; //Fix roles with RTM timer
// const version = '3.3.9'; //Enable Care Plan and PTU
//const version = '3.4.4'; //more fixes
// const version = '3.5.0'; //Bug fixes for Prompt and Care Plan
// const version = '3.5.1'; //Bug fixes for Prompt and Care Plan
// const version = '3.5.2'; //Care plan session max update, NPS implementation, Prompt fixes
// const version = '3.5.5'; //Release candidate
//const version = '3.5.6'; //Release candidate hiding some development features
// const version = '3.5.7' //staging release candidate
// const version = '3.5.8' //staging merge user photo fixes
// const version = '3.5.9' //HOTFIX BRG items
// const version = '3.5.10' //HOTFIX BRG items
//const version = '3.6.0'; //edit prescription revamp, messaging revamp
// const version = '3.6.1'; //edit prescription revamp, messaging revamp
// const version = '3.6.2'; //edit prescription revamp, messaging revamp
// const version = '3.6.3'; //edit prescription revamp, messaging revamp
// const version = '3.6.4'; //edit prescription revamp, messaging revamp
// const version = '3.6.5'; //template fix
// const version = '3.6.6'; //template fix
// const version = '3.6.7'; //more fixes
// const version = '3.6.8'; //more fixes
// const version = '3.6.9'; //more fixes
// const version = '3.6.10'; //more fixes
// const version = '3.6.11'; //more fixes
// const version = '3.6.12'; //more fixes
// const version = '3.6.13'; //more fixes
// const version = '3.6.14'; //more fixes
// const version = '3.6.15'; //Drag and drop fixes
// const version = '3.6.16'; //fix printout
// const version = '3.6.17'; //fix printout
// const version = '3.6.18'; //fix printout
// const version = '3.6.19'; //fix drag n drop
// const version = '3.7.0'; //reporting
// const version = '3.6.20'; //fix token
// const version = '3.6.21'; //fix exercise frequency
// const version = '3.6.22'; //more fixes exercise frequency
// const version = '3.7.0'; //reporting
// const version = '3.7.1'; //reporting
// const version = '3.7.5'; //part-time tags
// const version = '3.7.6'; //remove sticky scroll
// const version = '3.7.6'; //allow capitalization for prompt emails and filter issue
// const version = '3.7.7'; //allow capitalization for prompt emails and filter issue
// const version = '3.7.8'; //HOTFIX prompt integration
// const version = '3.8.0'; //
// const version = '3.8.1'; //HOTFIX print prescription modal
// const version = '3.8.2'; //HOTFIX messaging fix
// const version = '3.9.0'; //fixes and freezing fix
// const version = '3.9.1';
// const version = '3.10.0'; //RTM Updates
// const version = '3.10.1'; //RTM Updates
// const version = '3.10.2'; //RTM Updates
// const version = '3.10.3'; //template fix
// const version = '3.10.4'; //template fix
// const version = '3.10.5'; //template fix
// const version = '3.11.0'; //multiple prescription
// const version = '3.11.1'; //rtm billing fetch fix
// const version = '3.11.2'; //rtm billing fetch fix
// const version = '3.11.3'; //rtm billing fetch fix
// const version = '3.11.4'; //
// const version = '3.11.5'; //
// const version = '3.11.6'; //patient side messaging disable
// const version = '3.11.7'; //patient side messaging disable
// const version = '3.11.8'; //update prescription name
// const version = '3.11.9'; //fix favorites
// const version = '3.11.10'; //fix favorites
// const version = '3.11.11'; //remove assign provider
// const version = '3.11.12'; //multiple languages web
// const version = '3.11.13'; //multiple languages web
// const version = '3.11.13'; //multiple languages web
// const version = '3.11.14'; //multiple languages web
// const version = '3.11.15'; //pdf changes
// const version = '3.11.16'; //language fixes
// const version = '3.11.17'; //crash fix
// const version = '3.11.18'; //multiple prescription state management fix
// const version = '3.11.19'; //h2 pdf
// const version = '3.11.20'; //sort clinics alphabetically
// const version = '3.11.21'; //sort clinics in edit patient page
// const version = '3.11.22'; //remove pdf printout for h2
// const version = '3.11.23'; //nps survey fixes
// const version = '3.11.24'; //new badge fix on messages, resend setup link and group template fixes
// const version = '3.12.0'; //
// const version = '3.12.1'; //
// const version = '3.12.2'; //
// const version = '3.12.3'; //
// const version = '3.12.4'; //
// const version = '3.12.5'; //
// const version = '3.12.6'; //
// const version = '3.12.7'; //
// const version = '3.12.8'; //
// const version = '3.12.9'; //
// const version = '3.12.10'; //
// const version = '3.12.11'; //
// const version = '3.12.12'; //
// const version = '3.12.13'; //
// const version = '3.12.14'; //printout issue
// const version = '3.12.15'; //email validation issue
// const version = '3.12.16'; //NPS redirection issues
// const version = '3.12.17'; //NPS redirection issues
// const version = '3.12.18'; //Different Prescription fix
// const version = '3.12.19'; //fix messaging
// const version = '3.12.20'; //fix messaging
// const version = '3.12.21'; //fix messaging
// const version = '3.12.22'; //fix messaging
// const version = '3.12.23'; //fix messaging
// const version = '3.12.24'; //private templates and fixed printing
// const version = '3.12.25'; //private templates and fixed printing
// const version = '3.12.26'; //private templates and fixed printing
// const version = '3.12.27'; //private templates and fixed printing
// const version = '3.12.28'; //private templates and fixed printing
// const version = '3.12.29'; //private templates and fixed printing
// const version = '3.12.30'; //rtm and phone number fixes
// const version = '3.12.31'; //more bug fixes
// const version = '3.13.11'; //correct version mis-match
// const version = '3.13.12'; //update reports
// const version = '3.13.13'; //update reports
// const version = '3.13.14'; //update reports
// const version = '3.13.15'; //update reports
// const version = '3.13.16'; //fix printing
// const version = '3.13.17'; //fix patient more
// const version = '3.13.18'; //fix styling from languages
// const version = '3.13.19'; //fix styling from languages
// const version = '3.13.20'; //fix styling from languages
// const version = '3.13.21'; //
// const version = '3.13.22'; //
// const version = '3.13.23'; //
// const version = '3.13.25'; //
// const version = '3.13.24'; //
// const version = '3.13.25'; //
// const version = '3.13.26'; //
// const version = '3.13.27'; //
// const version = '3.13.28'; //
// const version = '3.13.29'; //
// const version = '3.13.30'; //
// const version = '3.13.31'; //
// const version = '3.13.32'; //
// const version = '3.13.33'; //
// const version = '3.13.34'; //
// const version = '3.13.35'; //
// const version = '3.13.36'; //
// const version = '3.13.37'; //
// const version = '3.13.38'; //
// const version = '3.13.39'; //
// const version = '3.13.40'; //
// const version = '3.13.41'; //
// const version = '3.13.42'; //
// const version = '3.13.44'; //
// const version = '3.13.46'; //
// const version = '3.13.47'; //
// const version = '3.13.48'; //
// const version = '3.13.49'; //
//const version = '3.13.50'; //
// const version = '3.15.0'; //
// const version = '3.15.1'; //
// const version = '3.15.2'; //
// const version = '3.15.3'; //
// const version = '3.15.4'; //
// const version = '3.15.5'; //
// const version = '3.15.6'; //
// const version = '3.15.7'; //
// const version = '3.15.8'; //
// const version = '3.15.9'; //
// const version = '3.15.10'; //
// const version = '3.15.11'; //
//const version = '3.15.12';
// const version = '3.15.13';
// const version = '3.15.14';
//  const version = '3.15.15';
// const version = '3.15.16';
// const version = '3.15.18';
// const version = '3.15.19';
// const version = '3.15.20';
// const version = '3.16.1';
// const version = '3.16.2';
// const version = '3.16.3';
// const version = '3.16.4';
// const version = '3.16.5';
// const version = '3.16.6';
// const version = '3.16.7';
// const version = '3.16.8';
// const version = '3.16.9';
// const version = '3.16.10';
// const version = '3.16.11';
// const version = '3.16.12';
// const version = '3.16.13';
// const version = '3.16.14';
// const version = '3.16.15';
// const version = '3.16.16';

// const version = '3.16.17';
// const version = '3.17.0';
// const version = '3.17.1';
// const version = '3.17.2';
// const version = '3.17.3';
// const version = '3.17.4';
// const version = '3.17.5';
// const version = '3.17.6';
// const version = '3.17.7';
// const version = '3.17.8';
// const version = '3.17.9';
// const version = '3.17.10';
// const version = '3.17.11';
// const version = '3.17.12';
// const version = '3.17.13';
// const version = '3.17.14';
// const version = '3.17.15';
// const version = '3.17.16';
// const version = '3.17.17';
// const version = '3.17.18';
// const version = '3.17.19';
// const version = '3.17.20';
const version = '3.17.21';



export default version;
